import { toDateString } from './http.helper';

export function addDays(date: string, days: number): string {
    const result = new Date(date);
    result.setDate(result.getDate() + +days);
    return toDateString(result);
}

export function addYear(date: string, year: number): string {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + year);
    return toDateString(result);
}

export function get2HoursLaterEpoch() {
    return Math.floor((new Date().getTime() / 1000) + (2 * 60 * 60 * 1000));
}

export function formatDateReport(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}
